import { useEffect, useState } from "react";
import LandingVideo from "../media/videos/b-roll.webm";

function Jumbotron() {
  const textArr = ["Home", "Bathroom", "Kitchen"];
  const [textIndex, setTextIndex] = useState(0);
  const [buildingText, setBuildingText] = useState(true);
  const [currentText, setCurrentText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleClick = () =>{
    document.getElementById("contact-us").scrollIntoView();
  }

  useEffect(() => {
    var text = textArr[textIndex];
    if (buildingText) {
      if (currentIndex < text.length) {
        const timeout = setTimeout(() => {
          setCurrentText((prevText) => prevText + text[currentIndex]);
          setCurrentIndex((prevIndex) => prevIndex + 1);
        }, 150);

        return () => clearTimeout(timeout);
      } else {
        //Update the states to reverse the word
        const timeout = setTimeout(() => {
          setBuildingText(false);
          setCurrentIndex((prevIndex) => prevIndex - 1);
          setCurrentText((prevText) => prevText.slice(0, -1));
        }, 3000);
        return () => clearTimeout(timeout);
      }
    } else {
      if (currentIndex >= 0) {
        const timeout = setTimeout(() => {
          setCurrentIndex((prevIndex) => prevIndex - 1);
          setCurrentText((prevText) => prevText.slice(0, -1));
        }, 150);
        return () => clearTimeout(timeout);
      } else {
        //Update the states to build the next word
        const timeout = setTimeout(() => {
          if (textIndex === 2) {
            setTextIndex(0);
          } else {
            setTextIndex(textIndex + 1);
          }
          setBuildingText(true);
          setCurrentIndex(0);
        }, 1000);
        return () => clearTimeout(timeout);
      }
    }
  }, [currentIndex]);
  return (
    <div className="jumbotron">
      <video
        className="jumbotron-video"
        src={LandingVideo}
        muted
        playsInline
        autoPlay
        loop
      ></video>
      <div className="landing-heading-mask">
        <h1 className="landing-heading">Building Your Dream</h1>
        <div className="typing-animation">
          <h1 className="landing-heading">&nbsp;{currentText}</h1>
        </div>
        <button className="btn btn-outline-light btn-lg landing-btn" onClick={handleClick}>
          Get Started
        </button>
      </div>
    </div>
  );
}

export default Jumbotron;
