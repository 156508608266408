import BathroomProjectImg from "../media/images/projects/project-1.webp";
import KitchenProjectImg from "../media/images/projects/kitchen-1.webp";
import KitchenProjectTwoImg from "../media/images/projects/kitchen-2.webp";
import BathroomProjectTwoImg from "../media/images/projects/bathroom-2.webp";

function Projects(){
    return(
        <div className="homepage-carousel">
            <div id="projectsCarousel" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#projectsCarousel" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#projectsCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#projectsCarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    <button type="button" data-bs-target="#projectsCarousel" data-bs-slide-to="3" aria-label="Slide 4"></button>
                </div>
                <div className="carousel-inner">
                    <div className="carousel-item active">
                    <img src={BathroomProjectImg} className="d-block" alt="Bathroom Project" />
                    </div>
                    <div className="carousel-item">
                        <img src={KitchenProjectImg} className="d-block" alt="Kitchen Project" />
                    </div>
                    <div className="carousel-item">
                        <img src={KitchenProjectTwoImg} className="d-block" alt="Kitchen 2 Project" />
                    </div>
                    <div className="carousel-item">
                        <img src={BathroomProjectTwoImg} className="d-block" alt="Bathroom 2 Project" />
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#projectsCarousel" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#projectsCarousel" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </div>
    )
}

export default Projects;