import Process from "./Process";
import Contact from "../contact/Contact";
import KitchenImg from "../media/images/services/kitchen-3.webp";

function Kitchen(){
    return (
        <div>
            <div className="page-banner kitchens-page-banner-bg">
        <h1 className="page-banner-mask">Kitchens</h1>
      </div>
      <div className="container services-intro">
        <h3 className="text-center orange">Transform Your Kitchen</h3>
        <p className="services-intro-content">
        Imagine stepping into a kitchen that perfectly blends style and functionality, 
        where every detail reflects your personal taste and culinary needs. 
        Our expert team is ready to bring your vision to life.
        </p>
      </div>
      <div className="services-pageservices">
        <div className="container services-pageservices-offer">
          <h1 className="text-center white">What We Offer</h1>
          <p className="white text-center">
            We offer a comprehensive range of kitchens renovation services to
            suit all your needs.
          </p>
          <br />
          <div className="row">
            <div className="col-lg-5 text-center">
              <img className="about-founder-img" src={KitchenImg} alt="Kitchen renovation services" />
            </div>
            <div className="col-lg-7 white">
              <ul>
                <li className="services-page-li">
                  <span className="orange">
                    <strong>Full Kitchen Remodels</strong>
                  </span>
                  <br />
                  Transform your entire kitchen with new layouts, appliances, custom cabinetry, countertops, backsplashes, lighting solutions, and energy-efficient upgrades.
                </li>
                <br />
                <li className="services-page-li">
                  <span className="orange">
                    <strong>Partial Renovations</strong>
                  </span>
                  <br />
                  Update specific elements such as cabinets, countertops, appliances, flooring, fixtures, and more to refresh your kitchen's look and functionality.
                </li>
                <br />
                <li className="services-page-li">
                  <span className="orange">
                    <strong>Accessible Kitchens</strong>
                  </span>
                  <br />
                  We specialize in creating kitchens that are both stylish and functional for everyone, incorporating easy-to-reach cabinets, non-slip flooring, wide aisles, and ergonomic fixtures.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Process />
      <Contact />
        </div>
    )
}

export default Kitchen;