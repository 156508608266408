import Process from "./Process";
import Contact from "../contact/Contact";
import BathroomImg from "../media/images/services/bathroom-4.webp";

function Bathrooms() {
  return (
    <div>
      <div className="page-banner bathrooms-page-banner-bg">
        <h1 className="page-banner-mask">Bathrooms</h1>
      </div>
      <div className="container services-intro">
        <h3 className="text-center orange">Transform Your Bathroom</h3>
        <p className="services-intro-content">
          Whether you're looking for a simple refresh or a complete
          transformation, our expert team is here to guide you through every
          step of the renovation process. Discover the difference a beautifully
          designed and functional bathroom can make in your home.
        </p>
      </div>
      <div className="services-pageservices">
        <div className="container services-pageservices-offer">
          <h1 className="text-center white">What We Offer</h1>
          <p className="white text-center">
            We offer a comprehensive range of bathroom renovation services to
            suit all your needs.
          </p>
          <br />
          <div className="row">
            <div className="col-lg-5 text-center">
              <img className="about-founder-img" src={BathroomImg} alt="Bathrrom renovation service" />
            </div>
            <div className="col-lg-7 white">
              <ul>
                <li className="services-page-li">
                  <span className="orange">
                    <strong>Full Bathroom Remodels</strong>
                  </span>
                  <br />
                  Transform your entire bathroom with a complete renovation.
                  This includes layout redesign, new fixtures and fittings,
                  custom cabinetry and storage solutions, high-quality flooring
                  and tiling, modern lighting solutions, and energy-efficient
                  upgrades.
                </li>
                <br />
                <li className="services-page-li">
                  <span className="orange">
                    <strong>Partial Renovations</strong>
                  </span>
                  <br />
                  Update specific elements of your bathroom with partial
                  renovations focusing on vanity and countertop replacements,
                  shower and bathtub upgrades, tile and flooring updates,
                  fixture replacements, and painting and wall treatments.
                </li>
                <br />
                <li className="services-page-li">
                  <span className="orange">
                    <strong>Accessible Bathrooms</strong>
                  </span>
                  <br />
                  We specialise in creating accessible bathrooms that are both
                  stylish and functional, featuring walk-in showers and
                  bathtubs, grab bars and handrails, non-slip flooring, wide
                  doorways and lower countertops, and easy-to-reach storage
                  solutions.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Process />
      <Contact />
    </div>
  );
}

export default Bathrooms;
