function Contact(){
    return(
        <div className="contact-us" id="contact-us">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 contact-us-info">
                        <h2 className="contact-us-heading">Lets have a chat</h2>
                        <p>
                            Ready to start your project or have a question? Reach out and we'll
                            get back to you as soon as possible!
                        </p>
                        <br/>
                        <h4 className="orange">Phone</h4>
                        <p>(02) 1234 5678</p>
                        <h4 className="orange">Email</h4>
                        <p>demo@email.com</p>
                        <h4 className="orange">Address</h4>
                        <p>123 Demo Street, Sydney NSW 2000</p>
                    </div>
                    <div className="contact-us-form col-md-6">
                        <form name="contactus" method="post">
                            <input type="hidden" name="form-name" value="contactus" />
                            <label className="form-label" htmlFor="name"><strong>Name</strong></label>
                            <input className="form-control" type="text" id="name" name="name" required />
                            <br/>
                            <label className="form-label" htmlFor="email"><strong>Email</strong></label> <br />
                            <input className="form-control" type="email" id="email" name="email" required />
                            <br/>
                            <label className="form-label" htmlFor="phone"><strong>Phone</strong></label> <br />
                            <input className="form-control" type="tel" id="phone" name="phone" pattern="[0-9]+" required maxLength="10" />
                            <br/>
                            <label className="form-label" htmlFor="suburb"><strong>Suburb</strong></label>
                            <input className="form-control" type="text" min="1" id="suburb" name="suburb" required />
                            <br/>
                            <label className="form-label" htmlFor="message"><strong>Message</strong></label> <br />
                            <textarea className="form-control" id="message" name="message" rows="4" required></textarea>
                            <br/>
                            <div className="text-center">
                                <input className="btn btn-outline-light btn-lg" type="submit" value="Submit" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact;