import BathroomImg from "../media/images/services/bathroom-2.webp";
import KitchenImg from "../media/images/services/kitchen.webp";
import LivingImg from "../media/images/services/living-room.webp";

function Services(){
    return (
        <div className="container text-center homepage-services">
            <div className="row">
                <div className="col-md-4 homepage-services-card">
                    <a href="/kitchens"><img className="pointer" src={KitchenImg} alt="Kitchen renovations" /></a>
                    <div className="text-start homepage-services-content">
                        <h3 className="orange">Kitchen Renovations</h3>
                        <p>Revamp your kitchen with our experts. From countertops to cabinets, we create a stylish, functional space for cooking and entertaining.</p>
                    </div>
                </div>
                <div className="col-md-4 homepage-services-card">
                    <a href="/bathrooms"><img className="pointer" src={BathroomImg} alt="Bathroom renovations" /></a>
                    <div className="text-start homepage-services-content">
                        <h3 className="orange">Bathroom Renovations</h3>
                        <p>Turn your bathroom into a luxury haven. Our team updates fixtures, tiles and more to craft a relaxing and stylish environment.</p>
                    </div>
                </div>
                <div className="col-md-4 text-start">
                    <a href="/complete"><img className="pointer" src={LivingImg} alt="Complete renovations" /></a>
                    <div className="text-start">
                        <h3 className="orange">Complete Renovations</h3>
                        <p>Give your home a fresh start with our complete renovation services. We deliver a cohesive, modern look throughout.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Services;