function Process() {
  return (
    <div>
      <div className="process-section">
        <div className="container">
          <h1 className="accordion-heading text-center">The Process</h1>
          <p className="text-center accordion-text">
            Here's how we ensure your renovation experience is smooth and enjoyable.
          </p>
          <div className="accordion" id="accordion-process">
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  <h4>1. Consultation</h4>
                </button>
              </h2>
              <div
                id="collapseOne"
                className="accordion-collapse collapse show"
                data-bs-parent="#accordion-process"
              >
                <div className="accordion-body">
                  <p>
                  We start with a detailed consultation to understand your needs, preferences, and budget. Our team will assess your current space, listening to your ideas and concerns. <br/><br/>We'll discuss various design possibilities and provide expert advice to help shape your vision. This initial meeting sets the foundation for a successful renovation project.
                  </p>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  <h4>2. Design</h4>
                </button>
              </h2>
              <div
                id="collapseTwo"
                className="accordion-collapse collapse"
                data-bs-parent="#accordion-process"
              >
                <div className="accordion-body">
                  <p>
                  Our talented designers will create a custom plan tailored to your specific requirements. <br/><br/>This phase includes detailed drawings, 3D renderings, and material selection. We collaborate with you to finalise every aspect of the design, from layout and color schemes to fixtures and finishes. <br/><br/>You'll have the opportunity to review and approve the design, ensuring it perfectly aligns with your vision before we move forward.
                  </p>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  <h4>3. Construction</h4>
                </button>
              </h2>
              <div
                id="collapseThree"
                className="accordion-collapse collapse"
                data-bs-parent="#accordion-process"
              >
                <div className="accordion-body">
                  <p>
                    Our skilled contractors take over to bring the design to life with precision and care. We manage the entire construction process, coordinating with suppliers and tradespeople to ensure everything runs smoothly. <br/><br/>Throughout the construction phase, we maintain open communication, providing regular updates and addressing any questions or concerns you may have. Our goal is to minimise disruption to your daily routine while delivering high-quality results.
                  </p>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  <h4>4. Completion</h4>
                </button>
              </h2>
              <div
                id="collapseFour"
                className="accordion-collapse collapse"
                data-bs-parent="#accordion-process"
              >
                <div className="accordion-body">
                  <p>
                    Once the renovation is complete, we conduct a thorough inspection to ensure everything meets our high standards and your expectations. We'll walk you through the finished space, making sure every detail is perfect. <br/><br/>Our team leaves your new bathroom clean, polished, and ready for you to enjoy. We stand by our work and offer post-renovation support should you need any assistance or adjustments.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Process;