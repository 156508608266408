import { useEffect, useRef, useState } from "react";
import { useIntersection } from "../IsVisible";

function Stats(){
    const [startCount, setStartCount] = useState(false);
    const [projectsCompletedCount, setProjectsCompletedCount] = useState(0);
    const [yearsExperience, setYearsExperience] = useState(0);
    const [onTime, setOnTime] = useState(0);
    const [customerSatisfaction, setCustomerSatisfaction] = useState(0);

    const triggerRef = useRef(null);
    const isVisible = useIntersection(triggerRef, "10px");

    useEffect(() => {
        if (isVisible) {
            const startCountTimeout = setTimeout(() =>{
                setStartCount(true);
            }, 200);
            return () => clearTimeout(startCountTimeout);
        }
    }, [isVisible]);

    useEffect(()=>{
        if(startCount){
            if(projectsCompletedCount !== 800){
                const projectsCompletedTimeout = setTimeout(() => {
                    setProjectsCompletedCount(projectsCompletedCount + 1);
                }, 2);
                return () => clearTimeout(projectsCompletedTimeout);
            }
        }
    }, [projectsCompletedCount, startCount]);

    useEffect(()=>{
        if(startCount){
            if(yearsExperience !== 20){
                const yearsExperienceTimeout = setTimeout(() => {
                    setYearsExperience(yearsExperience + 1);
                }, 120);
                return () => clearTimeout(yearsExperienceTimeout);
            }
        }
    }, [yearsExperience, startCount]);

    useEffect(()=>{
        if(startCount){
            if(onTime !== 98){
                const onTimeTimeout = setTimeout(() => {
                    setOnTime(onTime + 1);
                }, 25);
                return () => clearTimeout(onTimeTimeout);
            }
        }
    }, [onTime, startCount]);

    useEffect(()=>{
        if(startCount){
            if(customerSatisfaction !== 100){
                const customerSatisfactionTimeout = setTimeout(() => {
                    setCustomerSatisfaction(customerSatisfaction + 1);
                }, 25);
                return () => clearTimeout(customerSatisfactionTimeout);
            }
        }
    }, [customerSatisfaction, startCount]);

    return(
        <div className="container stats-container">
            <div className="text-center" ref={triggerRef}>
                <h1>Unmatched Success</h1>
                <div className="row stats-content">
                    <div className="col-md-3">
                        <h2 className="stats-count">{projectsCompletedCount}+</h2>
                        <p>Projects Completed</p>
                    </div>
                    <div className="col-md-3 stats-item">
                        <h2 className="stats-count">{yearsExperience}+</h2>
                        <p>Years of Experience</p>
                    </div>
                    <div className="col-md-3 stats-item">
                        <h2 className="stats-count">{onTime}%</h2>
                        <p>Projects Completed on Time</p>
                    </div>
                    <div className="col-md-3 stats-item">
                        <h2 className="stats-count">{customerSatisfaction}%</h2>
                        <p>Customer Satisfaction</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Stats;