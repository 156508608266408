import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <div>
    <nav className="navbar navbar-dark bg-dark navbar-expand-lg">
      <div className="container-fluid">
        <a className="navbar-brand" href="/">
          Reno
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <a className="nav-link" href="/">
                Home
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/about">
                About
              </a>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Services
              </a>
              <ul className="dropdown-menu dropdown-menu-start" aria-labelledby="navbarDropdownMenuLink">
                <li><a className="dropdown-item" href="/kitchens">Kitchens</a></li>
                <li><a className="dropdown-item" href="/bathrooms">Bathrooms</a></li>
                <li><a className="dropdown-item" href="/complete">Complete Home</a></li>
              </ul>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/contact">
                Contact Us
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <App />
    <div className="footer">
      <div className="container text-center">
        <a className="footer-text mask" href="/">
          <strong>Home</strong>
        </a>
        <a className="footer-text mask" href="/kitchens">
          <strong>Kitchens</strong>
        </a>
        <a className="footer-text mask" href="/bathrooms">
          <strong>Bathrooms</strong>
        </a>
        <a className="footer-text mask" href="/complete">
          <strong>Complete Home</strong>
        </a>
        <a className="footer-text mask" href="/about">
          <strong>About Us</strong>
        </a>
        <a className="footer-text mask" href="/contact">
          <strong>Contact Us</strong>
        </a>
        <hr className="footer-divider" />
        <p className="footer-text mask">
          &#169; Copyright 2024 - Peanut Bay Web
        </p>
      </div>
    </div>
  </div>
);