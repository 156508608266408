import "../App.css";
import Jumbotron from "./Jumbotron";
import Stats from "./Stats";
import Services from "./Services";
import Info from "./Info";
import Projects from "./Projects";
import Contact from "../contact/Contact";

function Home() {
  return (
    <div>
      <Jumbotron />
      <Stats />
      <Services />
      <Info />
      <Projects />
      <Contact />
    </div>
  );
}

export default Home;
