import Process from "./Process";
import Contact from "../contact/Contact";
import CompleteImg from "../media/images/services/house-2.webp";

function Complete(){
    return (
        <div>
            <div className="page-banner complete-page-banner-bg">
        <h1 className="page-banner-mask">Complete Renovations</h1>
      </div>
      <div className="container services-intro">
        <h3 className="text-center orange">Transform Your House</h3>
        <p className="services-intro-content">
        Whether you're planning to update a single room or overhaul your entire house, our professional team is here to turn your dreams into reality. From small upgrades to extensive transformations, we deliver a hassle-free renovation experience that revitalises your living space.
        </p>
      </div>
      <div className="services-pageservices">
        <div className="container services-pageservices-offer">
          <h1 className="text-center white">What We Offer</h1>
          <p className="white text-center">
            We offer a comprehensive range of complete home renovation services to
            suit all your needs.
          </p>
          <br />
          <div className="row">
            <div className="col-lg-5 text-center">
              <img className="about-founder-img" src={CompleteImg} alt="Complete renovation services" />
            </div>
            <div className="col-lg-7 white">
              <ul>
                <li className="services-page-li">
                  <span className="orange">
                    <strong>Whole-Home Renovations</strong>
                  </span>
                  <br />
                  Transform your entire residence with comprehensive renovations, including new layouts, modern fixtures and fittings, custom cabinetry, premium flooring and tiles, advanced lighting solutions, and energy-efficient improvements.                </li>
                <br />
                <li className="services-page-li">
                  <span className="orange">
                    <strong>Room-Specific Upgrades</strong>
                  </span>
                  <br />
                  Enhance particular areas of your home with focused renovations, whether it’s the kitchen, bathroom, living room, bedroom, basement, or more.                </li>
                <br />
                <li className="services-page-li">
                  <span className="orange">
                    <strong>Accessible Homes</strong>
                  </span>
                  <br />
                  We specialise in designing homes that are both stylish and accessible, featuring easy-to-reach cabinetry, slip-resistant flooring, wider doorways, and ergonomic fixtures to ensure comfort and usability for all.                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Process />
      <Contact />
        </div>
    )
}

export default Complete;