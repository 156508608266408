import Contact from "./Contact";

function ContactPage(){
    return(
        <div>
            <div className="page-banner contact-page-banner-bg">
                <h1 className="page-banner-mask">Contact Us</h1>
            </div>
            <Contact />
        </div>
    )
}

export default ContactPage;