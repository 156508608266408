import FounderImg from "../media/images/about/founder.webp";

function AboutPage(){
    return(
        <div>
            <div className="page-banner about-page-banner-bg">
                <h1 className="page-banner-mask">About Us</h1>
            </div>
            <div className="container about-content">
                <div className="row">
                    <div className="col-lg-5 text-center">
                        <img className="about-founder-img" src={FounderImg} alt="Founder of Reno" />
                    </div>
                    <div className="col-lg-7">
                        <h1>Our History</h1>
                        <p>Founded in 2004, Reno started as a modest family business with a commitment to delivering high-quality renovations. Our founder, John Davis, a master craftsman with a keen eye for detail, embarked on this journey with a mission to bring homeowners' visions to life. Over the years, our dedication to quality work and customer satisfaction has earned us a stellar reputation in the industry.</p>
                        <p>From our humble beginnings, we've grown into a full-service renovation company, specializing in bathrooms, kitchens, and whole-home renovations. Each project is a testament to our relentless pursuit of perfection and innovation. Our team of skilled professionals combines traditional craftsmanship with modern techniques, ensuring that every renovation is not only beautiful but also built to last.</p>
                    </div>
                </div>
                <br/>
                <div>
                    <h1>Why Choose Reno?</h1>
                    <p>At Reno, we understand that your home is a reflection of your personal style and a sanctuary for you and your family. Whether you’re looking to update your kitchen, remodel your bathroom, or undertake a complete home renovation, we are here to guide you through the process from concept to completion. Our extensive portfolio showcases a diverse range of projects, each tailored to meet the unique needs and preferences of our clients.</p>
                    <ul>
                        <li><strong>Experience:</strong> With over two decades of experience, we have honed our skills and refined our processes to deliver exceptional results.</li>
                        <br/>
                        <li><strong>Quality:</strong> We use only the finest materials and the latest techniques to ensure that your renovation stands the test of time.</li>
                        <br/>
                        <li><strong>Customer-Centric:</strong> Your satisfaction is our top priority. We work closely with you to understand your vision and bring it to life with precision and care.</li>
                        <br/>
                        <li><strong>Comprehensive Services:</strong> From initial consultation to final walkthrough, we manage every aspect of your renovation, ensuring a seamless and stress-free experience.</li>
                    </ul>
                    <p>Join the many satisfied homeowners who have trusted Reno to enhance their living spaces. Let us help you create a home that reflects your style, meets your needs, and exceeds your expectations. Contact us today to start your renovation journey with Reno.</p>
                    <a href="/contact"><button className="btn btn-outline-dark">Contact Us</button></a>
                </div>
            </div>
        </div>
    )
}

export default AboutPage;