import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./home/Home.js";
import ContactPage from "./contact/ContactPage.js";
import AboutPage from "./about/AboutPage.js";
import Bathrooms from "./services/Bathrooms.js";
import Kitchens from "./services/Kitchens.js";
import Complete from './services/Complete.js';

function App() {
  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route path="*" element={<Home />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="bathrooms" element={<Bathrooms />} />
          <Route path="kitchens" element={<Kitchens />} />
          <Route path="complete" element={<Complete />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
