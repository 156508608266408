function Info(){
    return(
        <div className="info">
            <div className="container info-container">
                <div className="row">
                    <div className="col-lg-6 info-card">
                        <p>TRUSTED EXPERIENCE</p>
                        <h2>Over 20 Years of Renovation Excellence</h2>
                        
                        <a href="/contact"><button className="btn btn-light">Contact Us</button></a>
                    </div>
                    <div className="col-lg-6">
                        <p>With over two decades of experience in the renovation industry, we bring unparalleled knowledge and skill to every project. Our extensive history means we’ve seen it all and perfected our craft, ensuring top quality results every time. Whether it’s a kitchen upgrade, bathroom remodel, or a complete home renovation, our seasoned professionals are dedicated to transforming your space with excellence and precision. Trust us to bring your renovation dreams to life.</p>
                        <br/>
                        <ul>
                            <li><p><strong>Free Quotes: </strong>Get started with no-obligation quotes tailored to your project.</p></li>
                            <br/>
                            <li><p><strong>Quality Craftsmanship: </strong>Our seasoned professionals ensure top-quality results.</p></li>
                            <br/>
                            <li><p><strong>Competitive Pricing: </strong>Enjoy cost-effective solutions without sacrificing quality.</p></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Info;